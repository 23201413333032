/* global TPay */
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './LandingPageContent.css';
import classNames from 'classnames';

function LandingPagePinFlow() {

  const navigate = useNavigate();
  const [redirectUrl, setRedirectUrl] = useState('http://login.kid-security.io/activate');
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [isHeader, setIsHeader] = useState(false);
  const [msisdn, setMsisdn] = useState(null);
  const [operatorCode, setOperatorCode] = useState("42402");
  const [subscriptionContractId, setSubscriptionContractId] = useState(null);
  const [subscriptionContractStatus, setSubscriptionContractStatus] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState(2422);
  const [selectedOperator, setSelectedOperator] = useState('EA');
  const [selectedPlan, setSelectedPlan] = useState('Daily');
  const [productCatalog, setProductCatalog] = useState('kidsecurity-etisalat');
  const [campaignId, setCampaignId] = useState(null);
  const [trackerId, setTrackerId] = useState(null);
  const [isGradientRightToLeft, setIsGradientRightToLeft] = useState(false);
  const [otp, setOtp] = useState([null, null, null, null]);
  const gatewayBaseUrl = "https://uaetpay.kid-security.io";

  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    var providedLang = queryParams.get('lang');
    const l = localStorage.getItem('lang') != null ? localStorage.getItem('lang').toUpperCase() : ((providedLang == null || (providedLang.toUpperCase() != 'EN' && providedLang.toUpperCase() != 'AR')) ? 'AR' : providedLang.toUpperCase());
    if (l === 'EN') { setRedirectUrl('http://activation.kid-security.io/activate'); }
    localStorage.setItem('lang', l)
    setSelectedLanguage(l);

    const isEnriched = TPay.HeaderEnrichment.enriched();
    if (isEnriched) {
      setIsHeader(true);
      const msisdn = TPay.HeaderEnrichment.msisdn();
      setMsisdn(msisdn.substring(3));
      const operatorCode = TPay.HeaderEnrichment.operatorCode();
      setOperatorCode(operatorCode);
      hasSubscriptionAsync();
      document.getElementById("msisdn").style.border = '';
      document.getElementById("msisdn").disabled = true;
    }
    else {
      TPay.HeaderEnrichment.init({ operatorCode: operatorCode });
      document.getElementById("msisdn").disabled = false;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
  }, [operatorCode, subscriptionPlanId, selectedOperator, selectedPlan]);

  const hasSubscriptionAsync = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const hasSubscription = await TPay.HeaderEnrichment.hasSubscription("0", callback);
        resolve(hasSubscription);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

  async function callback(result, subId) {
    if (result) {
      window.location.href = redirectUrl;
    }
  }

  const confirm = async () => {
    if (!isMsisdnValid(msisdn)) {
      document.getElementById('pre-input').textContent = selectedLanguage == 'EN' ? 'Incorrect Number Format' : 'رقم غير صحيح';
      document.getElementById("msisdn").style.border = "2px solid #AA4098";
      return;
    }
    document.getElementById('pre-input').textContent = selectedLanguage == 'EN' ? 'Enter your Etisalat mobile number' : 'ادخل رقم هاتف اتصالات';
    return new Promise(async (resolve, reject) => {
      try {
        const currentTimestamp = new Date().getTime();
        const customerAccountNumber = `${currentTimestamp}-${msisdn}`;
        var obj = {};
        obj.subscriptionPlanId = subscriptionPlanId;
        obj.productCatalog = productCatalog;
        obj.productSku = "0";
        obj.operatorCode = operatorCode;
        obj.msisdn = "971" + msisdn;
        obj.customerAccountNumber = customerAccountNumber;
        obj.merchantTemplateKey = "Kidsec";
        // obj.redirectURL = redirectUrl;
        const confirm = await TPay.HeaderEnrichment.confirm(obj, confirmCallback);
        resolve(confirm);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

  async function confirmCallback(status, refCode, contractDetails) {
    setSubscriptionContractId(contractDetails.subscriptionContractId);
    setSubscriptionContractStatus(contractDetails.subscriptionContractStatus);
    setTransactionId(contractDetails.transactionId);
    saveHistory(contractDetails);

    if (!status || contractDetails == null) {
      getMessage('999');
      return;
    }

    (async () => {
      const i = await isAlreadySubscribed(contractDetails, contractDetails.errorMessage);
      if (i) {
        return;
      }
    })();

    getError(contractDetails.errorMessage);

    if (refCode === null || refCode === undefined || refCode === '') {
      if (contractDetails.operationStatusCode === '0') {
        const data = {
          msisdn: msisdn, subscriptionContractId: contractDetails.subscriptionContractId, transactionId: contractDetails.transactionId,
          plan: selectedPlan
        };
        navigate(`/verify?lang=${selectedLanguage}`, { state: data });
        return;
      }
    }

    if (contractDetails.operationStatusCode === '0') {
      getMessage(contractDetails.paymentTransactionStatusCode);
    }
  }

  const isAlreadySubscribed = async (contractDetails, value) => {
    if (value !== null && value !== undefined && decodeURIComponent(value).includes("already subscribed")) {
      try {
        const parameters = new URLSearchParams(contractDetails);
        const response = await fetch(gatewayBaseUrl + '/send-sms?' + parameters.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.ok) {
          window.location.href = redirectUrl;
          return true;
        }
      } catch (error) {
        console.error('Error sending params:', error);
      }
    }
    return false;
  }

  const getError = (value) => {
    if (value === null || value === undefined || value === '') {
      return;
    }
    if (value.includes('Verification SMS Not Sent')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Verification SMS Not Sent' : 'لم يتم إرسال رسالة التحقق القصيرة';
    } else if (value.includes('Please enter valid phone number for the selected mobile operator')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please enter valid phone number for the selected mobile operator' : 'الرجاء إدخال رقم هاتف صالح لمشغل الهاتف المحمول المحدد';
    } else if (value.includes('This mobile number is blacklisted')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'This mobile number is blacklisted' : 'رقم الهاتف المحمول هذا مدرج في القائمة السوداء';
    } else if (value.includes('The mobile number or mobile operator are incorrect')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'The mobile number or mobile operator are incorrect' : 'رقم الهاتف المحمول أو مشغل الهاتف المحمول غير صحيح';
    } else if (value.includes('The given MSISDN got the free start period before')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'The given MSISDN got the free start period before' : 'حصل الرقم المحدد على فترة البدء المجانية من قبل';
    } else if (value.includes('wait for 2 minutes before issuing same request')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please wait for 2 minutes before issuing same request' : 'يرجى الانتظار لمدة دقيقتين قبل إصدار نفس الطلب';
    } else if (value.includes('This user already subscribed to the given product')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'You are already subscribed' : 'لم يتم اشتراكك بنجاح. انت مشترك.';
    } else if (value.includes('for same customer was cancelled or suspended previously and cool off period did not pass')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Subscription for same customer was cancelled or suspended previously and cool off period did not pass' : 'تم إلغاء أو تعليق الاشتراك لنفس العميل سابقًا ولم تنته فترة التهدئة';
    } else if (value.includes('Subscription Contract Is Already Verified')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Subscription Contract Is Already Verified' : 'تم التحقق من عقد الاشتراك بالفعل';
    } else if (value.includes('Send Verification Code Limit Exceeded')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Send Verification Code Limit Exceeded' : 'تم تجاوز الحد الأقصى لرمز التحقق';
    } else if (value.includes('The transaction has been rejected by T-Pay')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'The transaction has been rejected' : 'تم رفض المعاملة';
    } else if (value.includes('Operator Verfication Failed')) {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Operator Verfication Failed' : 'فشل التحقق من المشغل';
    } else {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    }
  }

  const getMessage = (value) => {
    if (value === null || value === undefined || value === '') {
      return;
    }
    if (value === '0') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Thank you for subscribing' : 'شكرا لأشتراكك';
      if (localStorage.getItem('lang') !== null && localStorage.getItem('lang') === 'EN') {
        window.location.href = 'http://activation.kid-security.io/activate';
        return;
      }
      window.location.href = 'http://login.kid-security.io/activate';
      return;
    } else if (value === '1') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '2') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'The process has been cancelled from your side.' : 'تم إلغاء العملية من جانبك';
    } else if (value === '3') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '4') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'You don\'t have enough balance.' : 'ليس لديك رصيد كافي';
    } else if (value === '5') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'You exceeded daily or monthly limits' : 'لقد تجاوزت الحدود اليومية أو الشهرية';
    } else if (value === '6') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '7') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '8') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '9') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '10') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please follow instructions in USSD Menu' : 'يرجى اتباع قائمة التعليمات';
    } else if (value === '11') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Entered PIN code is invalid.' : 'كود التحقق الذي تم إدخاله غير صالح';
    } else if (value === '12') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please confirm your payment' : 'يرجى تأكيد الدفع الخاص بك';
    } else if (value === '13') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Phone number provided doesn\'t exist in the chosen mobile operator.' : 'رقم الهاتف المقدم غير موجود في مشغل الهاتف المحمول المختار';
    } else if (value === '14') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Your phone number is not eligible for this operation. Please contact your mobile operator.' : 'رقم هاتفك غير مؤهل لهذه العملية. يرجى الاتصال بمشغل الهاتف المحمول الخاص بك';
    } else if (value === '15') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '16') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'PIN code entered is expired/ We have sent a new one' : 'كود التحقق المدخل منتهي الصلاحية. لقد أرسلنا واحد جديد';
    } else if (value === '17') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Your payment has been reverted' : 'تم إرجاع دفعتك';
    } else if (value === '18') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '19') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '20') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '21') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please check again in one minute' : 'يرجى التحقق مرة أخرى بعد دقيقة واحدة';
    } else if (value === '22') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'The payment is partially collected. Please top up your balance' : 'تم تحصيل الدفعة جزئياً. يرجى إعادة شحن رصيدك';
    } else if (value === '50') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '51') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    } else if (value === '100') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Payment succeeded, but push notification failed to be delivered' : 'تمت العملية بنجاح، ولكن فشل إرسال الإشعار الفوري';
    } else if (value === '101') {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Payment succeeded and our system tried to send the push notification 48 retries without a successful delivery' : 'تمت العملية بنجاح، وحاول نظامنا إرسال الإشعار الفوري 48 محاولة دون توصيل ناجح';
    } else {
      document.getElementById('result_msg').textContent = selectedLanguage === 'EN' ? 'Please try again or contact us.' : 'يرجى المحاولة فى وقت لاحق أو تواصل معنا';
    }
  }

  const handleSelectPlan = (title) => {
    setSelectedPlan(title);
    if (title === 'Daily') {
      setSubscriptionPlanId(2422);
      setProductCatalog('kidsecurity-etisalat');
    }
    else {
      setSubscriptionPlanId(2423);
      setProductCatalog('Kidsecurity-weekly');
    }
  };

  const getMsisdn = (event) => {
    setMsisdn(event.target.value);
    document.getElementById("msisdn").style.border = '';
  }

  const handleSelectLanguage = (event) => {
    const selectedLang = event.target.textContent.trim();
    setSelectedLanguage(selectedLang);
    localStorage.setItem('lang', selectedLang);
    setIsGradientRightToLeft(!isGradientRightToLeft);
    setRedirectUrl('http://login.kid-security.io/activate');
    if (selectedLang === 'EN') {
      setRedirectUrl('http://activation.kid-security.io/activate');
    }
    window.location.reload();
  };

  const gradientStyle = {
    background: isGradientRightToLeft
      ? 'linear-gradient(to left, #AA4098 50%, #D86F71 50%)'
      : 'linear-gradient(to right, #AA4098 50%, #D86F71 50%)',
  };

  useEffect(() => {
    if (queryParams.get("campaignId")) {
      setCampaignId(queryParams.get("campaignId"));
    }
    if (queryParams.get("trackerId")) {
      setTrackerId(queryParams.get("trackerId"));
    }
  }, [queryParams]);

  async function saveHistory(contractDetails) {
    try {
      const response = await fetch(gatewayBaseUrl + '/save-history',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            msisdn: msisdn,
            pinCode: otp.join(''),
            marketingUuid: campaignId,
            trackerId: trackerId,
            subscriptionContractId: contractDetails !== null ? contractDetails.subscriptionContractId : null,
            subscriptionContractStatus: contractDetails !== null ? contractDetails.subscriptionContractStatus : null,
            operatorCode: operatorCode,
            transactionId: contractDetails !== null ? contractDetails.transactionId : null,
            header: isHeader,
            service: productCatalog,
            customerAccountNumber: contractDetails.customerAccountNumber,
            language: selectedLanguage,
            message: contractDetails !== null ? contractDetails.errorMessage : null
          })
        });
    }
    catch (error) {
    }
  }

  async function exit() {
    window.location.href = 'http://www.google.com';
  }

  const isMsisdnValid = () => {
    if (msisdn !== null && msisdn !== undefined) {
      return msisdn.length === 9 && msisdn.startsWith('5');
    }
    return false;
  }



  return (

    <div className="App">

      <div className="upside" >
        <div className="header">
          <div className="logo">
            {
              selectedLanguage === 'AR' ? (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../kid-arabic.png' />) : (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../kid-english.png' />)
            }
          </div>
          <div className="language" style={gradientStyle} onClick={handleSelectLanguage}>
            <p>AR</p>
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path></svg>
            <p>EN</p>
          </div>
        </div>
        <p className="small-text">
          {selectedLanguage === 'EN' ? (selectedPlan === 'Daily' ? 'Free for 24h then 2 AED/day (VAT Inclusive).' : 'Free for 72h then 14 AED/day (VAT Inclusive).') : (selectedPlan === 'Daily' ? 'مجانًا لمدة 24 ساعة ! ثم ستُفرض على الخدمة 2 درهمًا إماراتياً في اليوم شاملاً ضريبة القيمة المضافة' : 'مجانًا لمدة 72 ساعة ! ثم ستُفرض على الخدمة 14 درهمًا إماراتياً في السبوع شاملاً ضريبة القيمة المضافة')}
        </p>
        <div className="title">
          <h1>{selectedLanguage === 'AR' ? 'تطبيق الرقابة الأبوية من أجل عائلة أكثر أمانًا' : 'Parental Control Application for a safer family!'}</h1>
          <div className="photo">
            {
              selectedLanguage === 'AR' ? (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../banner-arabic.png' />) : (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../banner-english.png' />)
            }
          </div>
        </div>
      </div>


      <div className="content">
        <div className="input">
          <p id='pre-input'>{selectedLanguage === 'AR' ? 'ادخل رقم هاتف اتصالات' : 'Enter your Etisalat mobile number'}</p>

          <div id='msisdn-input' className="textfield">
            <div className="cc">+971</div>
            <input id="msisdn" value={msisdn} placeholder="5xxxxxxxx" type="tel" maxLength={9} onChange={getMsisdn} autoComplete="off" />
          </div>

          <div id='pricing-input' className="pricing-container">
            <div className={classNames('pricing-box', { 'selected': selectedPlan === 'Daily' })} onClick={() => handleSelectPlan('Daily')}>
              <span>{selectedLanguage === 'EN' ? 'Daily 2AED' : 'يوميا ٢ درهم'}</span>
              <span>{selectedLanguage === 'EN' ? '-1 day free-' : '-يوم واحد مجانا-'}</span>
            </div>
            <div className={classNames('pricing-box', { 'selected': selectedPlan === 'Weekly' })} onClick={() => handleSelectPlan('Weekly')}>
              <span>{selectedLanguage === 'EN' ? 'Weekly 14AED' : 'اسبوعا ١٤ درهم'}</span>
              <span>{selectedLanguage === 'EN' ? '-3 days free-' : '-٣ أيام مجانا-'}</span>
            </div>
          </div>
          <div className='button-container'>
            <button id="pin" onClick={confirm}>{selectedLanguage === 'AR' ? 'إشترك' : 'Subscribe'}</button>
            <button id="exit" onClick={exit}>{selectedLanguage === 'AR' ? 'خروج' : 'Exit'}</button>
          </div>
          <div className="terms" style={{ direction: "rtl" }}>
            {
              selectedLanguage === 'AR' ?
                (
                  selectedPlan === 'Daily' ?
                    (
                      <p className='arabic' style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        مجانًا لمدة 24 ساعة ! ثم ستُفرض على الخدمة 2 درهمًا إماراتياً في اليوم شاملاً ضريبة القيمة المضافة
                        <span>بالضغط على 'اشترك'، سوف تتلقى رسالة تحتوي علي رمز سري لتأكيد اشتراكك.</span>
                      </p>
                    )
                    :
                    (
                      <p className='arabic' style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        مجانًا لمدة 72 ساعة ! ثم ستُفرض على الخدمة 14 درهمًا إماراتياً في اليوم شاملاً ضريبة القيمة المضافة
                        <span>بالضغط على 'اشترك'، سوف تتلقى رسالة تحتوي علي رمز سري لتأكيد اشتراكك</span>
                      </p>
                    )
                )
                :
                (
                  selectedPlan === 'Daily' ?
                    (
                      <p className='english' style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        Free for 24 hours, then 2 AED / day, including VAT
                        <span>By clicking on Subscribe you will receive PIN message to confirm subscription</span>
                      </p>
                    )
                    :
                    (
                      <p className='english' style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        Free for 72 hours, then 14 AED / day, including VAT
                        <span>By clicking on Subscribe you will receive PIN message to confirm subscription</span>
                      </p>
                    )
                )
            }
          </div>
          <p id="result_msg"></p>
        </div>
        <div className="terms" style={{ direction: "rtl" }}>
          {
            selectedLanguage === 'AR' ?
              (
                selectedPlan === 'Daily' ?
                  (
                    <p className='arabic' style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                      بالضغط على زر اشترك أعلاه، فأنت توافق على الشروط والأحكام التالية
                      <ul style={{ fontSize: '10px' }}>
                        <li><span>مجانا لمدة 24 ساعة! بعد ال24 ساعة سوف يتم تحصيل 2 درهم اماراتي يوميا شامل ضريبة القيمة المضافة</span></li>
                        <li><span>سوف يبدأ الاشتراك المدفوع تلقائياً بعد فترة التجربة المجانية</span></li>
                        <li><span>بدون أي التزامات، يمكنك إلغاء اشتراكك في أي وقت عن طريق إرسال الغاء كيدزسك إلى 1151</span></li>
                        <li><span>للحصول على الدعم، يرجى الاتصال على <a href='mailto:support@kidsecurity.net'>support@kidsecurity.net</a></span></li>
                        <li><span>التجربة المجانية صالحة فقط للمشتركين لأول مرة، استمتع بفترة التجربة المجانية لمدة 24 ساعة</span></li>
                        <li><span>يرجى التأكد من عدم استخدام أي تقنيات حجب من الطرف الثالث على متصفحك والتأكد من وجود اتصال إنترنت جيد لضمان الوصول السريع للمحتوى</span></li>
                        <li><span>للشروط والأحكام الإضافية <a href="#">برجاء الضغط هنا</a> و لسياسة الخصوصية <a href="#">برجاء الضغط هنا</a></span></li>
                        <li><span>بعض الميزات لا تعمل بشكل كامل على أجهزة آبل</span></li>
                        <li><span>بالمتابعة، أنت توافق على جميع الشروط والأحكام الخاصة بالخدمة وتوافق على استلام تحديثات حول اشتراكك على رقم الجوال المسجل</span></li>
                      </ul>

                    </p>
                  )
                  :
                  (
                    <p className='arabic' style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                      بالضغط على زر اشترك أعلاه، فأنت توافق على الشروط والأحكام التالية
                      <ul style={{ fontSize: '10px' }}>
                        <li><span>مجانا لمدة 72 ساعة! بعد ال72 ساعة سوف يتم تحصيل 14 درهم اماراتي اسبوعيا شامل ضريبة القيمة المضافة</span></li>
                        <li><span>سوف يبدأ الاشتراك المدفوع تلقائياً بعد فترة التجربة المجانية</span></li>
                        <li><span>بدون أي التزامات، يمكنك إلغاء اشتراكك في أي وقت عن طريق إرسال الغاء كيدزسك إلى 1151</span></li>
                        <li><span>للحصول على الدعم، يرجى الاتصال على <a href='mailto:support@kidsecurity.net'>support@kidsecurity.net</a></span></li>
                        <li><span>التجربة المجانية صالحة فقط للمشتركين لأول مرة، استمتع بفترة التجربة المجانية لمدة 24 ساعة</span></li>
                        <li><span>يرجى التأكد من عدم استخدام أي تقنيات حجب من الطرف الثالث على متصفحك والتأكد من وجود اتصال إنترنت جيد لضمان الوصول السريع للمحتوى</span></li>
                        <li><span>للشروط والأحكام الإضافية <a href="#">برجاء الضغط هنا</a> و لسياسة الخصوصية <a href="#">برجاء الضغط هنا</a></span></li>
                        <li><span>بعض الميزات لا تعمل بشكل كامل على أجهزة آبل</span></li>
                        <li><span>بالمتابعة، أنت توافق على جميع الشروط والأحكام الخاصة بالخدمة وتوافق على استلام تحديثات حول اشتراكك على رقم الجوال المسجل</span></li>
                      </ul>
                    </p>
                  )
              )
              :
              (
                selectedPlan === 'Daily' ?
                  (
                    <p className='english' style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                      TERMS & CONDITIONS:
                      <ul style={{ fontSize: '10px' }}>
                        <li><span>By Clicking on Subscribe you will agree to the terms & conditions below:</span></li>
                        <li><span>No commitment, you can cancel anytime by sending "Unsub KidSec" to “1151”</span></li>
                        <li><span>Free for 24 Hours! After 24 Hours the service will be charged at 2AED/1day VAT inclusive</span></li>
                        <li><span>By clicking on Subscribe you agree to the below terms and conditions</span></li>
                        <li><span>No commitment, you can cancel anytime by sending "Unsub KidSec" to “1151”</span></li>
                        <li><span>You will start the paid subscription automatically after your free trial</span></li>
                        <li><span>By subscribing to the service, you are giving your explicit consent to receive transactional and promotional messages related to the service on the subscribed mobile number</span></li>
                        <li><span>Renewal will be automatic every day</span></li>
                        <li><span>For support, please contact <a href='mailto:support@kidsecurity.net'>support@kidsecurity.net</a></span></li>
                        <li><span>The free trial is valid only for new subscribers</span></li>
                        <li><span>Please make sure that your browser is not using any 3rd-party blocking technologies and that you have a healthy internet connection for swift access to the content</span></li>
                        <li><span>Some features are not fully functional on apple devices</span></li>
                        <li><span>Further Terms and Conditions, <a href="https://uae.kid-security.io/terms?lang=EN">click here</a> & Privacy Policy, <a href="https://uae.kid-security.io/privacy?lang=EN">click here</a></span></li>
                      </ul>
                    </p>
                  )
                  :
                  (
                    <p className='english' style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                      TERMS & CONDITIONS:
                      <ul style={{ fontSize: '10px' }}>
                        <li><span>By Clicking on Subscribe you will agree to the terms & conditions below:</span></li>
                        <li><span>No commitment, you can cancel anytime by sending "Unsub KidSec" to “1151”</span></li>
                        <li><span>Free for 72 Hours! After 72 Hours the service will be charged at 14AED/7days VAT inclusive</span></li>
                        <li><span>By clicking on Subscribe you agree to the below terms and conditions</span></li>
                        <li><span>No commitment, you can cancel anytime by sending "Unsub KidSec" to “1151”</span></li>
                        <li><span>You will start the paid subscription automatically after your free trial</span></li>
                        <li><span>By subscribing to the service, you are giving your explicit consent to receive transactional and promotional messages related to the service on the subscribed mobile number</span></li>
                        <li><span>Renewal will be automatic every 7 days</span></li>
                        <li><span>For support, please contact <a href='mailto:support@kidsecurity.net'>support@kidsecurity.net</a></span></li>
                        <li><span>The free trial is valid only for new subscribers</span></li>
                        <li><span>Please make sure that your browser is not using any 3rd-party blocking technologies and that you have a healthy internet connection for swift access to the content</span></li>
                        <li><span>Some features are not fully functional on apple devices</span></li>
                        <li><span>Further Terms and Conditions, <a href="https://uae.kid-security.io/terms?lang=EN">click here</a> & Privacy Policy, <a href="https://uae.kid-security.io/privacy?lang=EN">click here</a></span></li>
                      </ul>
                    </p>
                  )
              )
          }
          {
            <Link to={`/terms?lang=${selectedLanguage}`}>
              {
                selectedLanguage === 'AR' ? 'أحكام وشروط' : 'Terms and Condition'
              }
            </Link>
          }
          {
            <Link to={`/privacy?lang=${selectedLanguage}`}>
              {
                selectedLanguage === 'AR' ? 'سياسة الخصوصية' : 'Privacy Policy'
              }
            </Link>
          }
        </div>
      </div>
    </div>

  );
}

export default LandingPagePinFlow;