import React, { useState, useEffect } from 'react';
import './LandingPageContent.css';
import { useLocation } from 'react-router-dom';

function LandingPageTC() {

  const location = useLocation();
  const [lang, setLang] = useState('AR');
  const [isGradientRightToLeft, setIsGradientRightToLeft] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const langParam = searchParams.get('lang');
    setLang(langParam);
  }, [location.search]);


  const handleSelectLanguage = (language) => {
    setLang(language);
    setIsGradientRightToLeft(!isGradientRightToLeft);
  };

  const gradientStyle = {
    background: isGradientRightToLeft
      ? 'linear-gradient(to left, #AA4098 50%, #D86F71 50%)'
      : 'linear-gradient(to right, #AA4098 50%, #D86F71 50%)',
  };


  return (

    <div className="App">

      <div className="upside" >
        <div className="header">
          <div className="logo">
            {
              lang === 'AR' ? (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../kid-arabic.png' />) : (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../kid-english.png' />)
            }
          </div>
          <div className="language" style={gradientStyle}>
            <p onClick={() => handleSelectLanguage('AR')}>AR</p>
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path></svg>
            <p onClick={() => handleSelectLanguage('EN')}>EN</p>
          </div>
        </div>

        <div className="title">
          {
            lang === 'AR' ? (<h1>تطبيق الرقابة الأبوية من أجل عائلة أكثر أمانًا</h1>) : (<h1>Parental Control Application for a safer family!</h1>)
          }
          <div className="photo">
            {
              lang === 'AR' ? (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../banner-arabic.png' />) : (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../banner-english.png' />)
            }
          </div>
        </div>
      </div>


      <div className="content">
        <div className="terms">
          {
            lang === 'AR' ?
              (
                <ul className='arabic' style={{ listStyleType: 'none' }}>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    مقدمة<br></br><br></br>
                    <li>تحدد هذه الوثيقة شروط الاستخدام (ويشار إليها فيما يلي بالشروط) التي يمكن للمستخدم بموجبها استخدام
                      "Tigrow"
                      و
                      "Kid Security"
                      ويشار إليه فيما يلي بالموقع)، وتطبيقات الهاتف المحمول
                      https://kidsecurity.org
                      (
                      .)
                      الخدمة
                      –
                      ويشار إليها فيما يلي بالتطبيقات وبالاشتراك مع الموقع وكافة الخدمات المقدمة على الموقع وفي التطبيقات
                      (
                      .
                      . يُرجى قراءة هذه الشروط والأحكام بعناية قبل استخدام الخدمة
                      تم تصميم الخدمة لضمان سلامة الأطفال والرقابة الأبوية
                      يمكن للمستخدم قبول شروط هذه السياسة فقط بالكامل، دون أي تحفظات أو قيود أو استثناءات. إذا لم يقبل المستخدم هذه
                      .الشروط بالكامل، فليس له الحق في استخدام الخدمة لأي غرض
                      ، يجب
                      Google PlayTM
                      و
                      Apple App Store
                      في حالة استخدام المستخدم للخدمة على منصات خارجية، بما في ذلك
                      .القواعد الخاصة بتلك المنصات والمتعلقة بالخدمة والامتثال لها
                      عليه قبول جميع</li>
                  </ul>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    المفاهيم الأساسية المستخدمة في السياسة<br></br><br></br>
                    <li>الموقع الإلكتروني هو مصدر معلومات مستضاف على شبكة الإنترنت وموجود على الشبكة العالمية للمعلومات
                      : .والاتصالات تحت اسم النطاق https://kidsecurity.org</li>
                    <li>"Kid Security" تطبيقات الهاتف المحمول
                      و
                      :
                      التطبيقات</li>
                    <li>الخدمة: التطبيقات مع الموقع وجميع الخدمات المقدمة على الموقع وفي التطبيقات</li>
                    <li>المستخدم: هو فرد موضوع البيانات الشخصية، الذي تتعلق به هذه البيانات، والذي يمتلك حق الوصول إلى الخدمة،
                      :
                      المستخدم
                      سواء كان مستخدمًا مسجلا ً أو غير مسجل، ويستخدم الخدمة لأغراض شخصية أو عائلية أو منزلية، وليس
                      .لأغراض الأعمال</li>
                    <li>البيانات الشخصية: معلومات تتعلق بشكل مباشر أو غير مباشر بمستخدم محدد أو يمكن التعرف عليه، مسجلة
                      أي على وسائط إلكترونية أو ورقية أو غيرها</li>
                    <li>الطفل: هو الشخص الذي لم يبلغ سن الرشد عند تثبيت واستخدام تطبيق Tigrow</li>
                    <li>مثبت للتواصل مع طفل تم إضافته إلى حسابه
                      Kid Security
                      هو الشخص الذي بلغ سن الرشد ولديه تطبيق
                      :
                      الوالد
                      •
                      .وفقًا لتشريعات البلد الذي ينطبق فيه هذا الشرط</li>
                  </ul>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    إطلاع المستخدم على الشروط وقبولها<br></br>
                    استخدام الخدمة<br></br><br></br>
                    <li>يجوز للمستخدم استخدام الخدمة فقط وفقًا لهذه الشروط وجميع القوانين والقواعد والتنظيمات المحلية والوطنية والدولية
                      .المعمول به</li>
                    <li>يُحظر تمامًا على أي مستخدم دون سن 18
                      "Kid Security".
                      عامًا إنشاء حساب في تطبيق</li>
                    <li>يمكن لأي فرد استخدام "Tigrow" ، ولكن فقط مع أطفاله البيولوجيين أو بالتبني الذين هو ممثلهم القانوني ويكونون دون سن عامًا
                      .
                      18</li>
                    <li>عامًا استخدام خدمة
                      18
                      يجوز للأشخاص الذين تقل أعمارهم عن فقط بعد الحصول على موافقة الوالدين أو الممثلين
                      Tigrow المعلومات السرية المتعلقة بالقاصر للانضمام إلى حسابهم.
                      القانونيين على حق استخدام الخدمة وإعطاء الموافقة على استخدام
                      -
                      https://yadi.sk/i/kGFAlaxBqWUgrw
                      يمكن العثور على نموذج موافقة الوالدين هنا</li>
                  </ul>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    المسؤولية<br></br><br></br>
                    <li>لا يمكن للشركة تقديم ضمانات بشأن تشغيل الخدمة أو مكوناتها ووظائفها الفردية على جهاز المستخدم بشكل خالٍ من
                      الأخطاء ودون انقطاع، إلا أن الشركة تسعى لتحسين جودة الخدمات. كما لا يمكن للشركة تقديم ضمانات بشأن توافق الخدمة مع الأهداف المحددة وتوقعات المستخدم، ولا تقدم أي ضمانات أخرى غير منصوص عليها صراحةً في هذا المستند</li>
                    <li>الشركة غير مسؤولة عن: تبعات الاستخدام غير المصرح به للخدمة من قبل أطراف ثالثة؛ أو عن الأضرار المباشرة أو غير
                      .المباشرة التي يعاني منها المستخدم نتيجة لاستخدام / </li>
                    <li>يكون المستخدم مسؤولاً عن جميع التصرفات التي يقوم بها عبر حسابه وعن جميع التصرفات التي تتم باستخدام اسم
                      المستخدم وكلمة المرور الخاصة به، وكذلك عن جميع التصرفات التي تتم من جهازه المحمول الذي تُستخدم عليه الخدمة أو
                      .على الموقع</li>
                    <li>يكون المستخدم مسؤولاً بشكل فردي أمام الأطراف الثالثة عن أفعاله أثناء استخدام الخدمة، بما في ذلك التأكد من أنها تتوافق
                      مسؤولية تسوية جميع مطالبات
                      مع المتطلبات القانونية ولا تنتهك حقوق ومصالح الأطراف الثالثة. يتعهد المستخدم بتحمل
                      .الأطراف الثالثة المتعلقة بأفعاله عند استخدام الخدمة، على نفقته الخاصة</li>
                    <li>الشركة غير مسؤولة عن فقدان الأجهزة (الأجهزة المحمولة وساعات ولا تقوم بنقل معلومات حول موقع الأجهزة
                      GPS) .لأطراف ثالثة</li>
                  </ul>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    التغييرات على الشروط<br></br><br></br>
                    <li>يجوز للشركة، وفقاً لتقديرها الخاص، تغيير أو تحديث هذه الشروط من وقت لآخر، وبالتالي يجب على المستخدم مراجعة هذه
                      الصفحة بشكل دوري. ستقوم الشركة بإخطار المستخدم قبل إجراء تغييرات جوهرية على هذه الشروط والأحكام، وستمنح
                      ة قبل مواصلة استخدام الخدمة. عند إجراء تغييرات جوهرية على الشروط،
                      المستخدم الفرصة للاطلاع على الشروط المعدل
                      . يُعدّ استمرار استخدام المستخدم للخدمة بعد أي تغييرات
                      ستقوم الشركة بتحديث تاريخ التعديل الأخير في أسفل هذه الصفحة
                      .بمثابة موافقة على الشروط الجديدة</li>
                    <li>تشكل هذه الشروط، جنباً إلى جنب مع أي تغييرات وأي اتفاقيات إضافية قد يبرمها المستخدم مع الشركة فيما يتعلق بالخدمة،
                      .الاتفاقية الكاملة بين المستخدم والشركة فيما يتعلق بالخدمة</li>
                  </ul>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    تفاصيل الاتصال<br></br><br></br>
                    <li>support@kidsecurity.net.يمكن للمستخدم التواصل مع الشركة عبر البريد الإلكترون</li>
                  </ul>
                </ul>
              )
              :
              (
                <ul className='english' style={{ listStyleType: 'none' }}>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    Introduction<br></br><br></br>
                    <li>This document sets out the Terms of Use (hereinafter referred to as the Conditions) under
                      which the User can use https://kidsecurity.org (hereinafter referred to as the Site), mobile
                      applications "Kid Security" and "Tigrow" (hereinafter referred to as Applications and
                      together with the Site and all services provided on the Site and in Applications – the
                      Service).
                      The service is designed to ensure the safety of children and parental control. Before using
                      the Service, please read these Terms and Conditions.
                      The terms of this Policy can be accepted by the User only in full, without any reservations,
                      restrictions and exceptions. If the User does not accept this provision of the Terms in full,
                      he has no right to use the Service for any purpose.
                      If the User uses the Service on external platforms, including the Apple App Store and
                      Google PlayTM, he must accept and comply with all the rules of such platforms applicable
                      to the Service.</li>
                  </ul>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    Basic concepts used in Politics<br></br><br></br>
                    <li>Website is an information resource hosted on the Internet information and
                      telecommunications network with a domain name https://kidsecurity.org .</li>
                    <li>Applications – mobile applications "Kid Security" and "Tigrow".
                      Service – Applications together with the Site and all services provided on the Site and in
                      Applications.</li>
                    <li>The User – the subject of personal data is an individual to whom personal data relates, who
                      has access to the Service, a registered and unregistered User who uses the Service for
                      personal, family, home and other uses not related to the implementation of entrepreneurial
                      activities.</li>
                    <li>Personal data – any information related directly or indirectly to a specific or identifiable
                      User, information recorded on electronic, paper and (or) other material media.</li>
                    <li>A child is a person who has not reached the age of majority at the time of installation and
                      use of the Tigrow mobile application.</li>
                    <li>A child is a person who has not reached the age of majority at the time of installation and
                      use of the Tigrow mobile application according to the legislation of the country where this
                      provision applies.</li>
                    <li>A parent is a person who has reached the age of majority, who has the Kid Security mobile
                      application installed to communicate with a Child who has been added to his account
                      according to the legislation of the country where this provision applies.</li>
                    <li>Familiarization and acceptance of the Terms by the User
                      Using the Service</li>
                    <li>The User may use the Service only in accordance with these Terms and Conditions and all
                      applicable local, state, national and international laws, rules and regulations.</li>
                    <li>Every User under the age of 18 is strictly prohibited from creating an account in the "Kid
                      Security" Application.</li>
                    <li>"Tigrow" can be used by any individual, but only in relation to their own or foster children,
                      whose legal representatives they are, under the age of 18.</li>
                    <li>Persons under the age of 18 may use the Tigrow Service only after obtaining consent from
                      parents or legal representatives for the right to use the Service and giving consent to the
                      use of confidential information about a minor to join their account. The parental consent
                      form can be found here - https://yadi.sk/i/kGFAlaxBqWUgrw</li>
                  </ul>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    Responsibility<br></br><br></br>
                    <li>The Company cannot provide guarantees regarding the error-free and uninterrupted
                      operation of the Service or its individual components and/or functions on the User's mobile
                      device, however, the Company strives to improve the quality of the Services. The Company
                      also cannot provide guarantees regarding the compliance of the Service with the specific
                      goals and expectations of the User, and also does not provide any other guarantees not
                      expressly specified in this document.</li>
                    <li>The Company is not responsible for: the consequences of unauthorized use of the Service
                      by third parties; for direct or indirect damage suffered by the User as a result of using / not
                      using the Service.</li>
                    <li>The User is responsible for all actions performed under his account and using his
                      username and password, as well as for all actions performed from his mobile device on
                      which the Application is used or on the Site.</li>
                    <li>The User is solely responsible to third parties for his actions when using the Service,
                      including for the fact that they comply with legal requirements and do not violate the rights
                      and legitimate interests of third parties. The User independently and at his own expense
                      undertakes to settle all claims of third parties related to the User's actions when using the
                      Service.</li>
                    <li>The company is not responsible for the loss of gadgets (mobile devices and GPS watches)
                      and does not transfer information about the location of gadgets to third parties.</li>
                  </ul>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    Changes to conditions<br></br><br></br>
                    <li>The Company may, at its sole discretion, change or update these Terms from time to time,
                      and therefore the User should periodically review this page.</li>
                    <li>The Company will notify the
                      User before making significant changes to these Terms and Conditions, and will give the
                      User the opportunity to familiarize themselves with the changed Terms before continuing to
                      use the Service.</li>
                    <li>When the Company changes the Terms in a significant way, the Company
                      will update the date of the last change at the bottom of this page. Further use of the
                      Service by the User after any such change means that the User agrees to the new Terms.</li>
                    <li>These Terms, together with any changes and any additional agreements that the User may
                      enter into with the Company in connection with the Service, constitute the entire
                      agreement between the User and the Company in relation to the Service.</li>
                  </ul>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none', textAlign: 'center' }}>
                    Contact details<br></br><br></br>
                    <li>Please, the User can contact the Company by e-mail support@kidsecurity.net.</li>
                  </ul>
                </ul>
              )
          }
        </div>
      </div>

    </div>

  );
}

export default LandingPageTC;