/* global CryptoJS */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const loadExternalScript = () => {
  return new Promise((resolve, reject) => {
    function getCurrentDate() {
      var now = new Date();
      var formattedDate = now.toISOString().slice(0, 19).replace("T", " ");
      return formattedDate + "Z";
    }
    var currentDate = getCurrentDate();
    function convertValue(inputString, p) {
      const hash = CryptoJS.HmacSHA256(inputString, p).toString(CryptoJS.enc.Hex);
      return hash;
    }

    const urlParams = new URLSearchParams(window.location.search);
    var language;
    if (localStorage.getItem('lang') !== null) {
      language = localStorage.getItem('lang').toLowerCase();
    }
    else {
      var providedLang = urlParams.get('lang');
      language = (providedLang == null || (providedLang.toLowerCase() !== 'en' && providedLang.toLowerCase() !== 'ar')) ? 'ar' : providedLang.toLowerCase();
    }

    if (window.location.pathname === '/terms' || window.location.pathname === '/privacy') {
      resolve();
      return;
    }

    const p = process.env.REACT_APP_LOCK;
    const convertedValue = convertValue(currentDate + language, p);
    var scriptElement = document.createElement('script');
    scriptElement.src = "https://enrichment.tpay.me/idxml.ashx/v2/js?date=" + currentDate + "&lang=" + language + "&digest=TfNE7tWGhNdjpVKqKGni:" + convertedValue;
    scriptElement.onload = resolve;
    scriptElement.onerror = reject;
    document.head.appendChild(scriptElement);
  });
};

const init = async () => {
  try {
    await loadExternalScript();

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } catch (error) {
    console.error(error);
  }
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
