
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPagePinFlow from './LandingPagePinFlow';
import LandingPageVerifyFlow from './LandingPageVerifyFlow';
import LandingPageTC from './LandingPageTC';
import LandingPagePrivacy from './LandingPagePrivacy';
import Page5098 from './5098/Page5098';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPagePinFlow />} />
        <Route path="/verify" element={<LandingPageVerifyFlow />} />
        <Route path="/terms" element={<LandingPageTC />} />
        <Route path="/privacy" element={<LandingPagePrivacy />} />

        {/* <Route path="/5098" element={<Page5098 />} /> */}
      </Routes>
    </Router>
  );
}

export default App;