import React, { useState, useEffect } from 'react';
import './LandingPageContent.css';
import { useLocation } from 'react-router-dom';

function LandingPagePrivacy() {

  const location = useLocation();
  const [lang, setLang] = useState('AR');
  const [isGradientRightToLeft, setIsGradientRightToLeft] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const langParam = searchParams.get('lang');
    setLang(langParam);
  }, [location.search]);


  const handleSelectLanguage = (language) => {
    setLang(language);
    setIsGradientRightToLeft(!isGradientRightToLeft);
  };

  const gradientStyle = {
    background: isGradientRightToLeft
      ? 'linear-gradient(to left, #AA4098 50%, #D86F71 50%)'
      : 'linear-gradient(to right, #AA4098 50%, #D86F71 50%)',
  };


  return (

    <div className="App">

      <div className="upside" >
        <div className="header">
          <div className="logo">
            {
              lang === 'AR' ? (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../kid-arabic.png' />) : (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../kid-english.png' />)
            }
          </div>
          <div className="language" style={gradientStyle}>
            <p onClick={() => handleSelectLanguage('AR')}>AR</p>
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path></svg>
            <p onClick={() => handleSelectLanguage('EN')}>EN</p>
          </div>
        </div>

        <div className="title">
          {
            lang === 'AR' ? (<h1>تطبيق الرقابة الأبوية من أجل عائلة أكثر أمانًا</h1>) : (<h1>Parental Control Application for a safer family!</h1>)
          }
          <div className="photo">
            {
              lang === 'AR' ? (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../banner-arabic.png' />) : (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../banner-english.png' />)
            }
          </div>
        </div>
      </div>


      <div className="content">
        <div className="terms">
          {
            lang === 'AR' ?
              (
                <ul className='arabic' style={{ listStyleType: 'none' }}>
                  <li> ١.١ تحدد سياسة الخصوصية هذه أهداف ومبادئ معالجة البيانات الشخصية، وهي الإجراءات التي تهدف إلى تجميع، وتخزين، وتغيير، وإضافة، واستخدام، وتوزيع البيانات الشخصية التي قد تتلقاها شركة Strong Bits LLC الخاصة أثناء التسجيل/التفويض و/أو استخدام المستخدمين للخدمة، وكذلك التدابير التي تتخذها الشركة لحماية البيانات الشخصية للمستخدمين</li>
                  <br></br>
                  <li>١.٢ يمكن للمستخدم قبول شروط هذه السياسة فقط بشكل كامل، دون أي تحفظات أو قيود أو استثناءات. عدم الإلمام بأحكام هذه السياسة لا يعفي المستخدم من المسؤولية عن عدم الالتزام بشروطها</li>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ٢. المصطلحات الأساسية المستخدمة في السياسة<br></br><br></br>
                    <li>&nbsp;&nbsp;٢.١ الموقع الإلكتروني هو مصدر معلومات يقع على شبكة الاتصالات والمعلومات "الإنترنت" مع اسم المجال https://kidsecurity.org</li><br></br>
                    <li>&nbsp;&nbsp;٢.٢ التطبيقات – التطبيقات المحمولة "Tigrow" و "Kid security"</li><br></br>
                    <li>&nbsp;&nbsp;٢.٣ الخدمة – التطبيقات بالإضافة إلى الموقع وجميع الخدمات المقدمة على الموقع وفي التطبيقات</li><br></br>
                    <li>&nbsp;&nbsp;٢.٤ المستخدم – موضوع البيانات الشخصية – فرد تتعلق به البيانات الشخصية، وله حق الوصول إلى الخدمة، سواء كان مستخدمًا مسجلاً أو غير مسجل يستخدم الخدمة لأغراض شخصية أو عائلية أو منزلية أو أخرى غير متعلقة بأنشطة تجارية</li><br></br>
                    <li>&nbsp;&nbsp;٢.٥ الشركة Strong Bits LLC العنوان القانوني: مبنى 71 بلوك A منطقة القوز، دبي، الإمارات العربية المتحدة، البريد الإلكتروني support@kidsecurity.org. : تنظم الشركة أو تقوم بجمع ومعالجة وحماية البيانات الشخصية الواردة .من المستخدمين</li><br></br>
                    <li>&nbsp;&nbsp;٢.٦ البيانات الشخصية – أي معلومات تتعلق مباشرة أو غير مباشرة بمستخدم معين، ومعلومات مسجلة على وسائط إلكترونية أو ورقية أو أخرى</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ٣. معلومات عن الشركة<br></br><br></br>
                    <li>&nbsp;&nbsp;٣.١ شركة Strong Bits LLC مسؤولة عن معالجة المعلومات الشخصية للمستخدم عند استخدام الخدمة وفقًا لقوانين حماية البيانات المطبقة</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ٤. إجراءات قبول هذه السياسة<br></br><br></br>
                    <li>&nbsp;&nbsp;٤.١ يعتبر قبول هذه السياسة موافقة غير مشروطة من قبل المستخدم على شروطها وتأكيده لقبولها بالكامل. عدم الإلمام بأحكام السياسة لا يعفي المستخدم من الالتزام بشروطها</li><br></br>
                    <li>&nbsp;&nbsp;٤.٢ يقبل المستخدم شروط هذه السياسة ويمنح الشركة موافقته على معالجة ونقل البيانات الشخصية بواحدة من الطرق التالية: بالنقر على الزر التفاعلي في الموقع: "متابعة"</li><br></br>
                    <li>&nbsp;&nbsp;٤.٣ تشكل هذه السياسة أيضًا جزءًا من شروط الاستخدام الموجودة في https://kidsecurity.net/termsofus</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ٥. شروط معالجة البيانات<br></br><br></br>
                    <li>&nbsp;&nbsp;٥.١ لا تتحقق الشركة من صحة البيانات الشخصية التي يقدمها المستخدم وليست مسؤولة عن أي عدم دقة في البيانات</li><br></br>
                    <li>&nbsp;&nbsp;٥.٢ تعني معالجة البيانات الشخصية في هذه السياسة جمع البيانات وتنظيمها وتخزينها وتعديلها وإضافتها واستخدامها وتوزيعها وتجميعها وإخفائها وحذفها وتدميرها</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ٦. خصوصية بيانات الأطفال<br></br><br></br>
                    <li>&nbsp;&nbsp;٦.١ تأخذ الشركة خصوصية معلومات الأطفال بجدية ولا تجمع أو تخزن البيانات الشخصية من الأطفال دون سن 14 عاما</li><br></br>
                    <li>&nbsp;&nbsp;٦.٢ إذا اكتشفت الشركة أن أحد المستخدمين لم يبلغ سن 14 عامًا، فستقوم باتخاذ الإجراءات المناسبة لحذف هذه المعلومات</li><br></br>
                    <li>&nbsp;&nbsp;٦.٣ من خلال السماح للطفل بإنشاء حساب مستخدم وتفويض الطفل لاستخدام الخدمة، فإن الوالد أو الوصي يوافق صراحةً 6.3 على الإجراءات الموضحة في هذه السياسة ويوافق على جمع واستخدام والكشف عن المعلومات الشخصية للطفل كما هو .موضح في هذه السياسة</li><br></br>
                    <li>&nbsp;&nbsp;٦.٤ قد تقوم الشركة بنقل أو كشف المعلومات الشخصية للطفل إلى مقدمي الخدمات من الأطراف الثالثة والشركاء التجاريين وفقًا لهذه السياسة. ومع ذلك، لا تستخدم الشركة أو تشارك المعلومات الشخصية التي تم جمعها من حسابات الأطفال الذين تقل أعمارهم عن 14 عامًا لأغراض التسويق أو الإعلان، ولا تسمح للشركاء من الأطراف الثالثة بجمع واستخدام هذه المعلومات لأغراض إعلانية</li><br></br>
                    <li>&nbsp;&nbsp;٦.٥ سيتم نقل معلومات حول ملف الطفل، وموقعه، وتسجيل الصوت حول جهاز الطفل، وإحصائيات استخدام الجهاز، وبيانات النشاط البدني إلى الوالد في تطبيق Kid Security المتصل بنفس الملف، وفقًا لهذه السياسة
                      <ul style={{ listStyleType: 'none' }}><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;٦.٥.١ ترسل Tigrow قائمة بالتطبيقات المثبتة إلى خادمنا https://rest.kidsecurity.tech و http://rest.gps watch.kz يقوم خادمنا بنقل البيانات إلى هاتف الوالد في تطبيق Kid Security</li><br></br>
                      </ul>
                    </li>
                    <li>&nbsp;&nbsp;٦.٦ يمكن للوالد/الوصي عرض أو حذف أو تغيير أو رفض المزيد من جمع أو استخدام بياناتهم الشخصية للطفل عن طريق الاتصال بالشركة عبر البريد الإلكتروني على support@kidsecurity.net</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ٧. مبادئ معالجة البيانات الشخصية<br></br><br></br>
                    <li>&nbsp;&nbsp;٧.١ تتم معالجة البيانات الشخصية على أساس قانوني وعادل</li><br></br>
                    <li>&nbsp;&nbsp;٧.٢ تقتصر معالجة البيانات الشخصية على تحقيق أغراض محددة ومشروعة ومقررة مسبقًا لا يُسمح بمعالجة البيانات الشخصية التي تتعارض مع أغراض جمع البيانات الشخصية</li><br></br>
                    <li>&nbsp;&nbsp;٧.٣ تخضع فقط البيانات الشخصية التي تلبي أغراض معالجتها للمعالجة. يتوافق محتوى وحجم البيانات الشخصية المعالجة مع الأغراض المعلنة للمعالجة لا يُسمح بمعالجة كميات زائدة من البيانات الشخصية مقارنةً بالأغراض المعلنة للمعالجة</li><br></br>
                    <li>&nbsp;&nbsp;٧.٤ .يجب على الشركة اتخاذ التدابير اللازمة و/أو التأكد من اتخاذها لإزالة أو توضيح البيانات غير الكاملة أو غير الدقيقة</li><br></br>
                    <li>&nbsp;&nbsp;٧.٥ .تعالج الشركة البيانات الشخصية للمستخدمين لفترة لا تتجاوز ما هو مطلوب لأغراض معالجة البيانات الشخصية</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ٨. أغراض استخدام البيانات الشخصية<br></br><br></br>
                    <li>&nbsp;&nbsp;٨.١ أغراض معالجة البيانات الشخصية للمستخدم:</li><br></br>
                    <ul style={{ listStyleType: 'none' }}>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;جمع ومعالجة الطلبات، وتقديم الردود، والتواصل مع المستخدمين؛</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;إرسال معلومات إدارية إلى المستخدمين، مثل المعلومات حول الخدمة والتغييرات في الشروط والسياسات. لا يمكن للمستخدمين اختيار عدم تلقي هذه الرسائل، حيث قد تكون المعلومات التي تحتوي عليها مهمة لاستخدام الخدمة؛</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;إرسال مواد أخبارية، ومواد إعلانية، ومعلومات إضافية عن الشركة أو منتجات الشركة، واستطلاعات، وأبحاث، وأحداث أخرى، في الحالات التي طلب فيها المستخدم أو أعطى موافقته لتلقي مثل هذه الاتصالات؛</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;تحسين الخدمة؛</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;ضبط إعدادات الخدمة وفقًا لرغبات المستخدم؛</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;تحديث وتطوير حسابات المستخدمين؛</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;تحليل ومراقبة استخدام الخدمات</li><br></br>
                    </ul>
                    <li>&nbsp;&nbsp;٨.٢ لا تتطلب الشركة الحصول على موافقة المستخدم لمعظم عمليات معالجة البيانات الشخصية. ومع ذلك، قد تتطلب الشركة موافقة المستخدم لبعض أنواع المعالجة. إذا كانت الشركة تتطلب موافقة المستخدم، فسوف تخبر الشركة بالمعلومات الشخصية التي تنوي الشركة استخدامها وكيف تنوي ا لشركة استخدامها. مع موافقة المستخدم، قد تقوم الشركة بمعالجة معلومات المستخدم لأغراض محددة إضافية</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ٩. نقل البيانات الشخصية<br></br><br></br>
                    <li>&nbsp;&nbsp;٩.١ لا تبيع الشركة أو تنقل أو تتبادل البيانات الشخصية للمستخدم مع الأطراف الثالثة لاستخدامها حسب تقديرهم الخاص (بما في ذلك وكالات إنفاذ القانون، أو الوكالات الحكومية الأخرى، أو المدعين المدنيين)، ما لم يكن ذلك مطلوبًا بموجب القانون أو أمر المحكمة</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ١٠. الروابط إلى مواقع وخدمات الأطراف الثالثة<br></br><br></br>
                    <li>&nbsp;&nbsp;١٠.١ الصوت) لمواقع وخدمات الأطراف الثالثة التي لا تتحكم فيها الشركة ولها مبادئ مختلفة للعمل مع البيانات الشخصية مقارنةً قد تحتوي الخدمات على روابط (بما في ذلك، على سبيل المثال لا الحصر، عناصر مثل الصور والخرائط وملفات انات شخصية إلى أي من هذه المواقع والخدمات، فإن استخدامها سيخضع بتلك المحددة في السياسة. في حال تم تقديم بي لسياسات الخصوصية المعتمدة من قبل تلك المواقع والخدمات. لا تتحمل الشركة أي مسؤولية عن أمان أو خصوصية أي قراءة سياسات الخصوصية معلومات يتم جمعها بواسطة مواقع أو خدمات الأطراف الثالثة، وتوصي بشدة بأن تقوم دائمًا ب .للمواقع والخدمات التي تزورها</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ١١. الامن و حماية البيانات الشخصية<br></br><br></br>
                    <li>&nbsp;&nbsp;١١.١ لتأمين أمان بيانات المستخدم الشخصية أثناء معالجتها، تتخذ الشركة التدابير القانونية والتنظيمية والمادية والتقنية اللازمة والكافية لحماية البيانات الشخصية من الوصول غير المصرح به أو العرضي إليها، أو تدميرها، أو تعديلها، أو حجبها، أو نسخها، أو توفيرها، أو توزيعها، وكذلك من غيرها من الأفعال غير القانونية من الأطراف الثالثة</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ١٢. تخزين البيانات<br></br><br></br>
                    <li>&nbsp;&nbsp;١٢.١ تحدد مدة تخزين البيانات الشخصية وفقًا لتاريخ تحقيق الأغراض من جمعها ومعالجتها</li><br></br>
                    <li>&nbsp;&nbsp;١٢.٢ تقوم الشركة بتخزين البيانات الشخصية في شكل يسمح بتحديد هوية المستخدم لفترة لا تتجاوز ما هو مطلوب لأغراض معالجة البيانات الشخصية</li><br></br>
                    <li>&nbsp;&nbsp;١٢.٣ إذا اختار المستخدم تلقي الاتصالات التسويقية من الشركة، ستحتفظ الشركة بمعلومات حول تفضيلات المستخدم التسويقية لفترة معقولة من الوقت من لحظة آخر إظهار للمستخدم اهتمامًا بمنتجات الشركة نحتفظ بالمعلومات التي تم الحصول عليها من ملفات تعريف الارتباط والتقنيات للتتبع لفترة معقولة من الوقت من تاريخ استلام هذه المعلومات</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ١٣. النقل عبر الحدود للبيانات الشخصية<br></br><br></br>
                    <li>&nbsp;&nbsp;١٣.١ سيتم النقل عبر الحدود للبيانات الشخصية من قبل المشغل مع الأخذ في الاعتبار أحكام الاتفاقية الخاصة بحماية الأفراد 13.1 ؛ المشار إليها فيما بعد بالاتفاقية). 28.01.1981 فيما يتعلق بالمعالجة الآلية للبيانات الشخصية (الموقعة في ستراسبورغ في د للبيانات الشخصية من قبل المشغل إلى البلدان التي صدقت على الاتفاقية المذكورة أعلاه، يمكن أن يتم النقل عبر الحدو وكذلك إلى البلدان التي تضمن حماية كافية لحقوق موضوعات البيانات الشخصية. في حال أصبح من الضروري إجراء النقل وكذلك تلك غير المدرجة في قائمة البلدان التي تضمن حماية عبر الحدود للبيانات الشخصية إلى دول لم تصدق على الاتفاقية، .كافية للبيانات الشخصية، سيحصل المشغل على موافقة منفصلة لهذه الخطوة من المستخدم</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ١٤. حقوق المستخدم<br></br><br></br>
                    <li>&nbsp;&nbsp;١٤.١ للمستخدم أو ممثله القانوني الحق في:</li><br></br>
                    <li>&nbsp;&nbsp;١٤.١.١ تلقي المعلومات المتعلقة بمعالجة بياناته الشخصية، باستثناء الحالات المنصوص عليها في التشريعات الخاصة بجمهورية كازاخستان؛</li><br></br>
                    <li>&nbsp;&nbsp;١٤.١.٢ المطالبة بأن تقوم الشركة بتغيير أو إضافة بياناته الشخصية إذا كانت هناك أسباب لذلك، مؤكدة بالوثائق ذات الصلة؛</li><br></br>
                    <li>&nbsp;&nbsp;١٤.١.٣ المطالبة بأن تقوم الشركة بحجب بياناته الشخصية إذا كانت هناك معلومات عن انتهاك شروط جمع ومعالجة البيانات الشخصية؛</li><br></br>
                    <li>&nbsp;&nbsp;١٤.١.٤ المطالبة بأن تقوم الشركة بتدمير بياناته الشخصية، التي تم جمعها ومعالجتها بشكل غير قانوني؛</li><br></br>
                    <li>&nbsp;&nbsp;١٤.١.٥ إعطاء الموافقة (أو الرفض) للشركة على توزيع بياناته الشخصية في مصادر البيانات الشخصية المتاحة للجمهور؛</li><br></br>
                    <li>&nbsp;&nbsp;١٤.١.٦ سحب الموافقة على جمع ومعالجة البيانات الشخصية؛</li><br></br>
                    <li>&nbsp;&nbsp;١٤.١.٧ ممارسة حقوق أخرى ينص عليها قانون البيانات الشخصية</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    ١٥. تغييرات على السياسة<br></br><br></br>
                    <li>&nbsp;&nbsp;١٥.١ تحتفظ الشركة بالحق في تغيير شروط هذه السياسة وجميع الأجزاء المتكاملة دون موافقة المستخدم، من خلال إخطار .الأخير بنشر نسخة جديدة من السياسة أو أي من أجزائها المتكاملة التي تم تغييرها على الموقع</li><br></br>
                    <li>&nbsp;&nbsp;١٥.٢ تدخل النسخة الجديدة من السياسة و/أو أي جزء متكامل منها حيز التنفيذ من لحظة نشرها على الموقع، ما لم يتم تحديد تاريخ آخر لدخول التعديلات حيز التنفيذ من قبل الشركة عند نشرها. النسخة الحالية من هذه السياسة وجميع ملحقاتها متاحة .للجمهور على الموقع</li><br></br>
                    <li>&nbsp;&nbsp;١٥.٣ يمكن للمستخدم أو ممثله القانوني سحب موافقته المسبقة على معالجة بياناته الشخصية عن طريق إرسال طلب في شكل مستند إلكتروني (نسخة ممسوحة ضوئيًا موقعة من الطلب) إلى الشركة عبر البريد الإلكتروني على support@kidsecurity.org مع موضوع "سحب الموافقة المسبقة على معالجة وتخزين البيانات الشخصية". يجب على المستخدم أو ممثله القانوني التوقف عن استخدام الخدمة فور إرسال الطلب المذكور إلى الشركة</li><br></br>
                    <li>&nbsp;&nbsp;١٥.٤ يمكن للمستخدم إرسال أي أسئلة أو تعليقات أو ملاحظات تتعلق بهذه السياسة، وكذلك أي طلبات تتعلق بمعلومات المستخدم الشخصية عبر البريد الإلكتروني إل kidsecurity.net.المستخدم</li><br></br>
                  </ul>
                </ul>
              )
              :
              (
                <ul className='english' style={{ listStyleType: 'none' }}>
                  <li>1.1. This Privacy Policy defines the purposes and general principles of processing personal
                    data, namely actions aimed at accumulating, storing, changing, supplementing, using,
                    distributing personal data that the Private Company Strong Bits LLC may receive during
                    registration/authorization and/or use of the Service by Users, as well as the measures
                    implemented by the Company to protect the personal data of Users.</li>
                  <br></br>
                  <li>1.2. The terms of this Policy may be accepted by the User only in full, without any
                    reservations, limitations or exceptions. Ignorance of the provisions of this Policy does not
                    relieve the User from liability for failure to comply with its terms.</li>
                  <br></br><br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    2. Basic concepts used in the Policy<br></br><br></br>
                    <li>&nbsp;&nbsp;2.1. The website is an information resource located on the information and
                      telecommunications network “Internet” with the domain name https://kidsecurity.org.</li><br></br>
                    <li>&nbsp;&nbsp;2.2. Applications – mobile applications “Kid security” and “Tigrow”.</li><br></br>
                    <li>&nbsp;&nbsp;2.3. Service – Applications together with the Site and all services provided on the Site and
                      in the Applications.</li><br></br>
                    <li>&nbsp;&nbsp;2.4. User – subject of personal data – an individual to whom the personal data relates,
                      having access to the Service, a registered and unregistered User using the Service for
                      personal, family, home and other use not related to the implementation of entrepreneurial
                      activities.</li><br></br>
                    <li>&nbsp;&nbsp;2.5. Company – Strong Bits LLC, Legal address: 71 0 FGB building, Block A, Al Goze Third
                      99999 Dubai, UAE. E-mail: support@kidsecurity.org. The Company organizes and (or)
                      carries out the collection, processing and protection of personal data received from Users,
                      and also determines the purposes of processing personal data, the composition of
                      personal data subject to processing, actions (operations) performed with personal data.</li><br></br>
                    <li>&nbsp;&nbsp;2.6. Personal data – any information directly or indirectly related to a specific or
                      determinable User, information recorded on electronic, paper and (or) other tangible
                      media.</li><br></br>
                    <li>&nbsp;&nbsp;2.7. Cookies are small text files in which the browser records data from websites visited by
                      Visitors.</li><br></br>
                    <li>&nbsp;&nbsp;2.8. IP address — a unique network address of a node in a computer network built using
                      the IP protocol, which allows determining the location of the User</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    3. Information about the Company<br></br><br></br>
                    <li>&nbsp;&nbsp;3.1. Strong Bits LLC company is responsible for the processing of the User's personal
                      information when using the Service in accordance with applicable data protection laws.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    4. Procedure for accepting this Policy<br></br><br></br>
                    <li>&nbsp;&nbsp;4.1. The fact of acceptance of this Policy means the User's full unconditional consent to its
                      terms and the User's acceptance, confirming its conclusion (signing). Ignorance of the
                      provisions of the Policy does not relieve the User from liability for failure to comply with its
                      terms.</li><br></br>
                    <li>&nbsp;&nbsp;4.2. The User accepts the terms of this Personal Data Processing Policy and provides the
                      Company with consent to the processing and transfer of personal data in one of the
                      following ways: by the User clicking the interactive button on the website: “Continue”.</li><br></br>
                    <li>&nbsp;&nbsp;4.3. This Policy also forms part of the Terms of Use located at
                      https://kidsecurity.net/termsofus (hereinafter referred to as the Terms).</li><br></br>
                    <li>&nbsp;&nbsp;4.4. By clicking the "Continue" button, the User also confirms the fact that he/she has
                      reached the age of 18, is an adult, a legally competent citizen and has the right to give
                      consent to the processing of his/her personal data by the Company, and if the User has not
                      reached the age of 18, by clicking the button it is confirmed that the Consent to the
                      processing of personal data is provided by his/her legal representative (parent, guardian,
                      etc.).</li><br></br>
                    <li>&nbsp;&nbsp;4.5. The current version of the Privacy Policy is valid from December 08, 2022.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    5. Terms of data processing<br></br><br></br>
                    <li>&nbsp;&nbsp;5.1. The Company does not verify the accuracy of the personal data provided by the User
                      and is not responsible for the inaccuracy of the personal data provided by the User. The
                      Company assumes that the information provided by the User is accurate and sufficient.</li><br></br>
                    <li>&nbsp;&nbsp;5.2. The processing of personal data in the Policy means the collection of personal data,
                      their systematization, accumulation, storage, modification, addition, use, distribution,
                      depersonalization, blocking, deletion and destruction of personal data.</li><br></br>
                    <li>&nbsp;&nbsp;5.3. Personal data voluntarily provided by the User during the registration process,
                      subscription to the Service:
                      <ul style={{ listStyleType: 'none' }}><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Registration and account data such as name, phone number, email address, country, city,
                          preferred language. Also for the Tigrow app - the child's nickname, profile photo and GPS
                          from the watch;</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Information in the correspondence sent by the User, which may include messages in the
                          Tigrow application chat, emails and recordings of telephone calls that the Company
                          receives from the User.</li><br></br>
                      </ul>
                    </li>
                    <li>&nbsp;&nbsp;5.4. Personal data that the Company collects when using the Service:
                      <ul style={{ listStyleType: 'none' }}><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;General information about the User’s location, including information about the country,
                          region, city, including data on the exact geographic location;</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Information about the use of the Service.</li><br></br>
                      </ul>
                    </li>
                    <li>&nbsp;&nbsp;5.5. The Company may collect the following information about the Users of the Tigrow
                      application:
                      <ul style={{ listStyleType: 'none' }}><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;location (geolocation) - The Company collects and uses the unique user identifier and the
                          location of the mobile device on which the Tigrow application is installed using GPS, WiFi
                          or wireless network triangulation to obtain the location of the mobile device for the purpose
                          of providing the Service. The Company retains location information only for as long as it is
                          reasonable to provide the Service, and then deletes the location data associated with the
                          User's account in the Kid Security mobile application. The Company may maintain location
                          data for a longer period of time to analyze aggregate trends and metrics. If the User wishes
                          to opt out of location data collection, the User should restrict the application's access to
                          location data in the settings on the mobile device on which the application is installed;</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;the child’s nickname created by the User. The Company does not collect information
                          about the child’s first and last name; any nickname can be used to designate the User.</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;a photo for the Avatar, photos from the GPS watch. The User can use the image without
                          identifying the Child. The Company does not store photos of the Child's image on the
                          server; only the Parent has access to this data;</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;sound around the device at the request of the parent - for a 20-second recording upon
                          request or broadcasting of an audio signal within a radius of no more than two meters for
                          the User with the Tigrow application installed, while listening is provided only to users with
                          the Kid Security mobile application installed, who are registered and connected to the
                          same account. In this case, information is displayed on the Child's mobile device that the
                          audio signal is being recorded. Sound recordings around the device or audio data are
                          stored on the server for 30 (thirty) days or until the Parent deletes this data in the
                          application;</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;access to device usage statistics – for the purpose of displaying the type of sound mode
                          on the child’s phone, the battery charge of the child’s device, information on the
                          percentage of time spent using other applications on the child’s phone exclusively in the
                          application of the verified parent. The Company does not store the above-mentioned data
                          of the Child on the server; only the Parent has access to this data;</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;physical activity data - to display this information in the linked and verified parent profile
                          in the Kid Security application. The Company does not store the Child’s physical activity
                          data on the server; only the Parent has access to this data;</li><br></br>
                      </ul>
                    </li>
                    <li>&nbsp;&nbsp;5.6. Information that the Company collects automatically using cookies, web beacons, log
                      files or other tracking/recording tools:
                      <ul style={{ listStyleType: 'none' }}><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;IP address</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;browser information</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;links to / exits and URLs</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;flow data and information about how the User interacts with links on a website, mobile
                          application or Service</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;domain names</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;landing pages, page views</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;cookie data that allows the Company to uniquely identify the User’s browser and track
                          the browser’s behavior on the Company’s Website</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;type of mobile device</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;mobile device identifiers or other persistent identifiers</li><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;location data collected from the User’s mobile device</li><br></br>
                      </ul>
                    </li>
                    <li>
                      &nbsp;&nbsp;5.7. The Company may collect analytics data or use third-party analytics tools such as
                      facebook events, adjust, userx.pro, appmetrica to measure traffic and usage trends for the
                      Service. These tools collect information sent by the User's browser or mobile device,
                      including the pages the User visits, the use of third-party applications and other
                      information that helps in analyzing and improving the service.
                    </li><br></br>
                    <li>
                      &nbsp;&nbsp;5.8. The Company also collects anonymous data about visitors and use of the Service for
                      the purpose of creating statistics or reports. However, such data does not directly or
                      indirectly relate to a specific individual, and no individual can be identified from the
                      anonymous data that the Company collects for these purposes.
                    </li><br></br>
                    <li>&nbsp;&nbsp;5.9. The Company does not collect or process information about the User’s race or
                      nationality, political views or membership in any political association, religious or
                      philosophical views, genetic or biometric data.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    6. Confidentiality of children's personal data<br></br><br></br>
                    <li>&nbsp;&nbsp;6.1 The Company takes the privacy of children's information seriously. The Company does
                      not collect or store personal data from children under the age of 14 (hereinafter referred to
                      as the Child). Children under the age of 14 must have the consent of a parent/guardian
                      before providing any personal data. If the Company determines that a user has not reached
                      the age of 14, it will not use or store their personal data without the consent of their
                      parent/guardian.</li><br></br>
                    <li>&nbsp;&nbsp;6.2 If the Company learns that it has inadvertently collected personal data of a child
                      without parental consent, the Company will take appropriate steps to delete this
                      information. The User's parent or guardian must immediately notify the Company that a
                      child under 14 has a registered account with the Service without their consent by email at
                      support@kidsecurity.net so that the Company can delete the child's personal data from
                      the system and terminate any account the child has created.</li><br></br>
                    <li>&nbsp;&nbsp;6.3 By allowing a child to create a user account and authorizing the child to use the Service,
                      the parent or guardian expressly consents to the actions described in this Policy and agrees
                      to the collection, use and disclosure of the child's personal information as described in this
                      Policy.</li><br></br>
                    <li>&nbsp;&nbsp;6.4. The Company may transfer or disclose a child's personal information to its third-party
                      service providers and business partners in accordance with this Policy. However, the
                      Company does not use or share personal information collected from accounts of children
                      under 14 years of age for marketing or advertising purposes, and does not permit third-
                      party partners to collect and use such information for advertising purposes.</li><br></br>
                    <li>&nbsp;&nbsp;6.5. Information about the child’s profile, his/her location, sound recording around the
                      child’s device, device usage statistics, and physical activity data will be transferred to the
                      Parent in the Kid Security application connected to the same profile, in accordance with
                      this Policy.
                      <ul style={{ listStyleType: 'none' }}><br></br>
                        <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;6.5.1. Tigrow sends a list of installed applications to our server https://rest.kidsecurity.tech
                          and http://rest.gps-watch.kz. Our server transmits data to the parent's phone in the Kid
                          Security application.</li><br></br>
                      </ul>
                    </li>
                    <li>&nbsp;&nbsp;6.6. A parent/guardian may view, delete, change or refuse further collection or use of their
                      child's personal data by contacting the Company by email at support@kidsecurity.net.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    7. Principles of personal data processing<br></br><br></br>
                    <li>&nbsp;&nbsp;7.1. The processing of personal data is carried out on a lawful and fair basis.</li><br></br>
                    <li>&nbsp;&nbsp;7.2. The processing of personal data is limited to achieving specific, predetermined and
                      legitimate purposes. Processing of personal data that is incompatible with the purposes of
                      collecting personal data is not permitted.</li><br></br>
                    <li>&nbsp;&nbsp;7.3. Only personal data that meet the purposes of their processing are subject to
                      processing. The content and volume of personal data being processed correspond to the
                      stated purposes of processing. Excessive amounts of personal data being processed in
                      relation to the stated purposes of their processing are not permitted.</li><br></br>
                    <li>&nbsp;&nbsp;7.4. The Company shall take the necessary measures and/or ensure that they are taken to
                      remove or clarify incomplete or inaccurate data.</li><br></br>
                    <li>&nbsp;&nbsp;7.5. The Company processes the personal data of Users no longer than required by the
                      purposes of processing the personal data.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    8. Purposes of using personal data<br></br><br></br>
                    <li>&nbsp;&nbsp;8.1. Purpose of processing the User’s personal data:</li><br></br>
                    <ul style={{ listStyleType: 'none' }}>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;collecting and processing requests, providing responses and communicating with Users;</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;to send administrative information to users, such as information about the Service and
                        changes to terms and policies. Users cannot opt out of receiving such messages, as the
                        information they contain may be important to the use of the Service;</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;sending Users news materials, advertising materials, additional information about the
                        Company or the Company’s products, surveys, research and other events, in cases where
                        the User has requested or given consent to receive such communications;</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;improve the Service;</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;set the Service settings in accordance with the User’s wishes;</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;update and develop user accounts;</li><br></br>
                      <li>&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;analyze and monitor the use of the Services.</li><br></br>
                    </ul>
                    <li>&nbsp;&nbsp;8.2. The Company is not required to obtain the User's consent for most personal data
                      processing operations. However, the Company may require the User's consent for certain
                      types of processing. If the Company requires the User's consent, the Company will inform
                      the Company of the personal data that the Company intends to use and how the Company
                      intends to use it. With the User's consent, the Company may process the User's
                      information for additional specific purposes.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    9. Transfer of personal data<br></br><br></br>
                    <li>&nbsp;&nbsp;9.1. The Company does not sell, transfer, or exchange the User's personal data with third
                      parties for use at their own discretion (including law enforcement agencies, other
                      government agencies, or civil plaintiffs), unless required by law or court order.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    10. Links to third-party sites and services<br></br><br></br>
                    <li>&nbsp;&nbsp;10.1. The Services may contain links (including, without limitation, such elements as
                      images, maps, audio files) to third-party websites and services that the Company does not
                      control and that have different principles for working with personal data than those defined
                      in the Policy. In the event that personal data is provided to any such websites and services,
                      their use will be governed by the privacy policies adopted by such websites and services.
                      The Company is not responsible for the security or privacy of any information collected by
                      third-party websites or services, and strongly recommends that you always read the
                      privacy policies of the websites and services you visit.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    11. Security and protection of personal data<br></br><br></br>
                    <li>&nbsp;&nbsp;11.1. To ensure the security of the User’s personal data during their processing, the
                      Company takes the necessary and sufficient legal, organizational, physical and technical
                      measures to protect personal data from unauthorized or accidental access to them, their
                      destruction, modification, blocking, copying, provision, distribution, as well as from other
                      illegal actions of third parties.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    12. Data storage<br></br><br></br>
                    <li>&nbsp;&nbsp;12.1. The storage period of personal data is determined by the date of achieving the
                      purposes of their collection and processing.</li><br></br>
                    <li>&nbsp;&nbsp;12.2. The Company stores personal data in a form that allows the User to be identified for
                      no longer than is required by the purposes of processing the personal data.</li><br></br>
                    <li>&nbsp;&nbsp;12.3 If the User has chosen to receive marketing communications from the Company, the
                      Company will retain information about the User’s marketing preferences for a reasonable
                      period of time from the moment the User last showed interest in the Company’s products.
                      We retain information obtained from cookies and other tracking technologies for a
                      reasonable period of time from the date of receipt of such information.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    13. Cross-border transfer of personal data<br></br><br></br>
                    <li>&nbsp;&nbsp;13.1. The cross-border transfer of Personal Data by the Operator shall be carried out taking
                      into account the provisions of the Convention for the Protection of Individuals with regard
                      to Automatic Processing of Personal Data (concluded in Strasbourg on 28.01.1981;
                      hereinafter referred to as the Convention). The cross-border transfer of Personal Data may
                      be carried out by the Operator to countries that have ratified the above Convention, as well
                      as to countries that ensure adequate protection of the rights of Personal Data subjects. In
                      the event that it becomes necessary to carry out the cross-border transfer of Personal Data
                      to countries that have not ratified the Convention, as well as those not included in the List
                      of countries ensuring adequate protection of Personal Data, the Operator shall obtain
                      separate consent for this action from the User.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    14. User Rights<br></br><br></br>
                    <li>&nbsp;&nbsp;14.1. The User or his legal representative has the right to:</li><br></br>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;14.1.1. receive information concerning the processing of his personal data, except for
                      cases stipulated by legislative acts of the Republic of Kazakhstan;</li><br></br>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;14.1.2. demand that the Company change or supplement their personal data if there are
                      grounds for doing so, confirmed by relevant documents;</li><br></br>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;14.1.3. demand that the Company block their personal data if there is information about a
                      violation of the terms of collection and processing of personal data;</li><br></br>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;14.1.4. demand that the Company destroy their personal data, the collection and
                      processing of which was carried out in violation of the law;</li><br></br>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;14.1.5. give consent (refuse) to the Company to distribute their personal data in publicly
                      available sources of personal data;</li><br></br>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;14.1.6. revoke consent to the collection and processing of personal data;</li><br></br>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;14.1.7. to exercise other rights provided for by the Law on Personal Data.</li><br></br>
                  </ul>
                  <br></br><br></br>
                  <ul style={{ listStyleType: 'none' }}>
                    15. Changes to Policy<br></br><br></br>
                    <li>&nbsp;&nbsp;15.1. The Company reserves the right to change the terms of this Policy and all integral
                      parts without the consent of the User, by notifying the latter by posting on the Site a new
                      version of the Policy or any of its integral parts that have been subject to changes.</li><br></br>
                    <li>&nbsp;&nbsp;15.2. A new version of the Policy and/or any integral part thereof shall enter into force from
                      the moment of publication on the Website, unless another date for the entry into force of
                      the amendments is determined by the Company upon their publication. The current
                      version of this Policy and all appendices thereto is publicly available on the Website.</li><br></br>
                    <li>&nbsp;&nbsp;15.3. The User or his legal representative may revoke their previously given consent to the
                      processing of their personal data by sending an application in the form of an electronic
                      document (a signed scanned copy of the application) to the Company by e-mail at
                      support@kidsecurity.org. with the subject line "Revocation of previously given consent to
                      the processing and storage of personal data". The User or his legal representative shall
                      cease using the Service immediately after sending the said application to the Company.</li><br></br>
                    <li>&nbsp;&nbsp;15.4. The User may send any questions, comments or remarks regarding this Policy, as well
                      as any requests related to the User’s personal information by email to
                      support@kidsecurity.net.</li><br></br>
                  </ul>
                </ul>
              )
          }
        </div>
      </div>
    </div>
  );
}

export default LandingPagePrivacy;