import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './LandingPageContent.css';

function LandingPageVerifyFlow() {

  const otpContainerRef = useRef([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [msisdn, setMsisdn] = useState(null);
  const [subscriptionContractId, setSubscriptionContractId] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('Daily');
  const [isGradientRightToLeft, setIsGradientRightToLeft] = useState(false);
  const [otp, setOtp] = useState([null, null, null, null]);
  const gatewayBaseUrl = "https://uaetpay.kid-security.io";

  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    var providedLang = queryParams.get('lang');
    const l = localStorage.getItem('lang') != null ? localStorage.getItem('lang').toUpperCase() : ((providedLang == null || (providedLang.toUpperCase() != 'EN' && providedLang.toUpperCase() != 'AR')) ? 'AR' : providedLang.toUpperCase());
    localStorage.setItem('lang', l)
    setSelectedLanguage(l);
    otpContainerRef.current[0].focus();
    const data = location.state;
    setMsisdn(data.msisdn);
    setSubscriptionContractId(data.subscriptionContractId);
    setTransactionId(data.transactionId);
    setSelectedPlan(data.plan);
  }, []);

  const handleSelectLanguage = (event) => {
    const selectedLang = event.target.textContent.trim();
    setSelectedLanguage(selectedLang);
    localStorage.setItem('lang', selectedLang);
    setIsGradientRightToLeft(!isGradientRightToLeft);
    window.location.reload();
  };

  const gradientStyle = {
    background: isGradientRightToLeft
      ? 'linear-gradient(to left, #AA4098 50%, #D86F71 50%)'
      : 'linear-gradient(to right, #AA4098 50%, #D86F71 50%)',
  };

  const handleInputChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    if (index < otp.length - 1 && value !== '') {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
    setOtp(newOtp);
  };

  //Verify Contract Flow
  async function verifyContract() {
    if (otp.join('').length !== 4) {
      const otpElements = document.querySelectorAll('[id^="otp-input-"]');
      otpElements.forEach((element) => {
        element.style.border = "2px solid #AA4098";
      });
      return;
    }
    try {
      const response = await fetch(gatewayBaseUrl + '/verify-contract',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            msisdn: msisdn,
            pinCode: otp.join(''),
            subscriptionContractId: subscriptionContractId,
            transactionId: transactionId
          })
        });
      if (response.ok) {
        const result = await response.json();
        document.getElementById("result_msg").textContent = result.message;
        console.log(result);
        if (result.code === 2000) {
          if (localStorage.getItem('lang') !== null && localStorage.getItem('lang') === 'EN') {
            window.location.href = 'http://activation.kid-security.io/activate';
            return;
          }
          window.location.href = 'http://login.kid-security.io/activate';
          return;
        }
      }
      else {
        document.getElementById("result_msg").textContent = 'Verify Contract Failed';
      }
    }
    catch (error) {
      document.getElementById("result_msg").textContent = 'Verify Contract Failed';
    }
  }

  async function exit() {
    window.location.href = 'http://www.google.com';
  }

  return (

    <div className="App">

      <div className="upside" >
        <div className="header">
          <div className="logo">
            {
              selectedLanguage === 'AR' ? (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../kid-arabic.png' />) : (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../kid-english.png' />)
            }
          </div>
          <div className="language" style={gradientStyle} onClick={handleSelectLanguage}>
            <p>AR</p>
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeWidth="2" d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path></svg>
            <p>EN</p>
          </div>
        </div>

        <p className="small-text">
          {selectedLanguage === 'EN' ? (selectedPlan === 'Daily' ? 'Free for 24h then 2 AED/day (VAT Inclusive)' : 'Free for 72h then 14 AED/day (VAT Inclusive)') : (selectedPlan === 'Daily' ? 'مجاني لمدة 24 ساعة ثم 2 درهم/يوم (شامل ضريبة القيمة المضافة)' : 'مجاني لمدة 72 ساعة ثم 14 درهم/يوم (شامل ضريبة القيمة المضافة)')}
        </p>


        <div className="title">
          <h1>{selectedLanguage === 'AR' ? 'تطبيق الرقابة الأبوية من أجل عائلة أكثر أمانًا' : 'Parental Control Application for a safer family!'}</h1>
          <div className="photo">
            {
              selectedLanguage === 'AR' ? (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../banner-arabic.png' />) : (<img alt='' loading='lazy' src='%PUBLIC_URL%/../../banner-english.png' />)
            }
          </div>
        </div>
      </div>


      <div className="content">
        <div className="input">
          <p id='pre-input'>{selectedLanguage === 'AR' ? 'املأ الرمز السري' : 'Fill Your OTP'}</p>

          <div id='otp-input' className='otp-input-container'>
            {otp.map((digit, index) => (
              <input key={index} id={`otp-input-${index}`} type="tel" maxLength="1" value={digit}
                onChange={(e) => handleInputChange(index, e.target.value)} className="otp-input" autoComplete="off"
                ref={(input) => (otpContainerRef.current[index] = input)} />
            ))}
          </div>
          <div className='button-container'>
            <button id="verify" onClick={verifyContract}>{selectedLanguage === 'AR' ? 'أشترك' : 'Subscribe'}</button>
            <button id="exit" onClick={exit}>{selectedLanguage === 'AR' ? 'خروج' : 'Exit'}</button>
          </div>
          <div className="terms" style={{ direction: "rtl" }}>
            {
              selectedLanguage === 'AR' ?
                (
                  selectedPlan === 'Daily' ?
                    (
                      <p className='arabic' style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        مجانًا لمدة 24 ساعة ! ثم ستُفرض على الخدمة 2 درهمًا إماراتياً في اليوم شاملاً ضريبة القيمة المضافة
                        <span>عند النقر على اشتراك، ستقوم بتأكيد الاشتراك</span>
                      </p>
                    )
                    :
                    (
                      <p className='arabic' style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        مجانًا لمدة 72 ساعة ! ثم ستُفرض على الخدمة 14 درهمًا إماراتياً في السبوع شاملاً ضريبة القيمة المضافة
                        <span>عند النقر على اشتراك، ستقوم بتأكيد الاشتراك</span>
                      </p>
                    )
                )
                :
                (
                  selectedPlan === 'Daily' ?
                    (
                      <p className='english' style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        Free for 24 hours, then 2 AED / day, including VAT
                        <span>By clicking on Subscribe you will confirm your subscription</span>
                      </p>
                    )
                    :
                    (
                      <p className='english' style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                        Free for 72 hours, then 14 AED / day, including VAT
                        <span>By clicking on Subscribe you will confirm your subscription</span>
                      </p>
                    )
                )
            }
          </div>
          <p id="result_msg"></p>
        </div>
        <div className="terms" style={{ direction: "rtl" }}>
          {
            selectedLanguage === 'AR' ?
              (
                selectedPlan === 'Daily' ?
                  (
                    <p className='arabic' style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                      بالضغط على زر اشترك أعلاه، فأنت توافق على الشروط والأحكام التالية
                      <ul style={{ fontSize: '10px' }}>
                        <li><span>مجانا لمدة 24 ساعة! بعد ال24 ساعة سوف يتم تحصيل 2 درهم اماراتي يوميا شامل ضريبة القيمة المضافة</span></li>
                        <li><span>سوف يبدأ الاشتراك المدفوع تلقائياً بعد فترة التجربة المجانية</span></li>
                        <li><span>بدون أي التزامات، يمكنك إلغاء اشتراكك في أي وقت عن طريق إرسال الغاء كيدزسك إلى 1151</span></li>
                        <li><span>للحصول على الدعم، يرجى الاتصال على <a href='mailto:support@kidsecurity.net'>support@kidsecurity.net</a></span></li>
                        <li><span>التجربة المجانية صالحة فقط للمشتركين لأول مرة، استمتع بفترة التجربة المجانية لمدة 24 ساعة</span></li>
                        <li><span>يرجى التأكد من عدم استخدام أي تقنيات حجب من الطرف الثالث على متصفحك والتأكد من وجود اتصال إنترنت جيد لضمان الوصول السريع للمحتوى</span></li>
                        <li><span>للشروط والأحكام الإضافية <a href="#">برجاء الضغط هنا</a> و لسياسة الخصوصية <a href="#">برجاء الضغط هنا</a></span></li>
                        <li><span>بعض الميزات لا تعمل بشكل كامل على أجهزة آبل</span></li>
                        <li><span>بالمتابعة، أنت توافق على جميع الشروط والأحكام الخاصة بالخدمة وتوافق على استلام تحديثات حول اشتراكك على رقم الجوال المسجل</span></li>
                      </ul>

                    </p>
                  )
                  :
                  (
                    <p className='arabic' style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                      بالضغط على زر اشترك أعلاه، فأنت توافق على الشروط والأحكام التالية
                      <ul style={{ fontSize: '10px' }}>
                        <li><span>مجانا لمدة 72 ساعة! بعد ال72 ساعة سوف يتم تحصيل 14 درهم اماراتي اسبوعيا شامل ضريبة القيمة المضافة</span></li>
                        <li><span>سوف يبدأ الاشتراك المدفوع تلقائياً بعد فترة التجربة المجانية</span></li>
                        <li><span>بدون أي التزامات، يمكنك إلغاء اشتراكك في أي وقت عن طريق إرسال الغاء كيدزسك إلى 1151</span></li>
                        <li><span>للحصول على الدعم، يرجى الاتصال على <a href='mailto:support@kidsecurity.net'>support@kidsecurity.net</a></span></li>
                        <li><span>التجربة المجانية صالحة فقط للمشتركين لأول مرة، استمتع بفترة التجربة المجانية لمدة 24 ساعة</span></li>
                        <li><span>يرجى التأكد من عدم استخدام أي تقنيات حجب من الطرف الثالث على متصفحك والتأكد من وجود اتصال إنترنت جيد لضمان الوصول السريع للمحتوى</span></li>
                        <li><span>للشروط والأحكام الإضافية <a href="#">برجاء الضغط هنا</a> و لسياسة الخصوصية <a href="#">برجاء الضغط هنا</a></span></li>
                        <li><span>بعض الميزات لا تعمل بشكل كامل على أجهزة آبل</span></li>
                        <li><span>بالمتابعة، أنت توافق على جميع الشروط والأحكام الخاصة بالخدمة وتوافق على استلام تحديثات حول اشتراكك على رقم الجوال المسجل</span></li>
                      </ul>
                    </p>
                  )
              )
              :
              (
                selectedPlan === 'Daily' ?
                  (
                    <p className='english' style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                      TERMS & CONDITIONS:
                      <ul style={{ fontSize: '10px' }}>
                        <li><span>By Clicking on Subscribe you will agree to the terms & conditions below:</span></li>
                        <li><span>No commitment, you can cancel anytime by sending "Unsub KidSec" to “1151”</span></li>
                        <li><span>Free for 24 Hours! After 24 Hours the service will be charged at 2AED/1day VAT inclusive</span></li>
                        <li><span>By clicking on Subscribe you agree to the below terms and conditions</span></li>
                        <li><span>No commitment, you can cancel anytime by sending "Unsub KidSec" to “1151”</span></li>
                        <li><span>You will start the paid subscription automatically after your free trial</span></li>
                        <li><span>By subscribing to the service, you are giving your explicit consent to receive transactional and promotional messages related to the service on the subscribed mobile number</span></li>
                        <li><span>Renewal will be automatic every day</span></li>
                        <li><span>For support, please contact <a href='mailto:support@kidsecurity.net'>support@kidsecurity.net</a></span></li>
                        <li><span>The free trial is valid only for new subscribers</span></li>
                        <li><span>Please make sure that your browser is not using any 3rd-party blocking technologies and that you have a healthy internet connection for swift access to the content</span></li>
                        <li><span>Some features are not fully functional on apple devices</span></li>
                        <li><span>Further Terms and Conditions, <a href="https://uae.kid-security.io/terms?lang=EN">click here</a> & Privacy Policy, <a href="https://uae.kid-security.io/privacy?lang=EN">click here</a></span></li>
                      </ul>
                    </p>
                  )
                  :
                  (
                    <p className='english' style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
                      TERMS & CONDITIONS:
                      <ul style={{ fontSize: '10px' }}>
                        <li><span>By Clicking on Subscribe you will agree to the terms & conditions below:</span></li>
                        <li><span>No commitment, you can cancel anytime by sending "Unsub KidSec" to “1151”</span></li>
                        <li><span>Free for 72 Hours! After 72 Hours the service will be charged at 14AED/7days VAT inclusive</span></li>
                        <li><span>By clicking on Subscribe you agree to the below terms and conditions</span></li>
                        <li><span>No commitment, you can cancel anytime by sending "Unsub KidSec" to “1151”</span></li>
                        <li><span>You will start the paid subscription automatically after your free trial</span></li>
                        <li><span>By subscribing to the service, you are giving your explicit consent to receive transactional and promotional messages related to the service on the subscribed mobile number</span></li>
                        <li><span>Renewal will be automatic every 7 days</span></li>
                        <li><span>For support, please contact <a href='mailto:support@kidsecurity.net'>support@kidsecurity.net</a></span></li>
                        <li><span>The free trial is valid only for new subscribers</span></li>
                        <li><span>Please make sure that your browser is not using any 3rd-party blocking technologies and that you have a healthy internet connection for swift access to the content</span></li>
                        <li><span>Some features are not fully functional on apple devices</span></li>
                        <li><span>Further Terms and Conditions, <a href="https://uae.kid-security.io/terms?lang=EN">click here</a> & Privacy Policy, <a href="https://uae.kid-security.io/privacy?lang=EN">click here</a></span></li>
                      </ul>
                    </p>
                  )
              )
          }
          {
            <Link to={`/terms?lang=${selectedLanguage}`}>
              {
                selectedLanguage === 'AR' ? 'أحكام وشروط' : 'Terms and Condition'
              }
            </Link>
          }
          {
            <Link to={`/privacy?lang=${selectedLanguage}`}>
              {
                selectedLanguage === 'AR' ? 'سياسة الخصوصية' : 'Privacy Policy'
              }
            </Link>
          }
        </div>
      </div>
    </div>

  );
}

export default LandingPageVerifyFlow;